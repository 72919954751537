import React, { useState } from "react";

import { useNavigate } from 'react-router-dom';
import { 
  CssBaseline,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import { 
  AuthService,
  LoginContainer,
  LoginTemplate,
  loginUser,
  useMessageDispatch,
} from 'itaf';

import { AppRoutes } from "../../routes";

const http = AuthService;

export default function Login () {
  const history = useNavigate();

  const setMessage = useMessageDispatch();

  const theme = useTheme();
  const [isLoading, setLoading] = useState(false);

  function loginClicked(username, password) {
    setLoading(true);
    
    http.login(username, password)
      .then((response) => {
        loginUser(response);

        history(AppRoutes.APP);
      })
      .catch(() => {
        setMessage({ hasError: true, text: 'Usuário ou senha inválidos!' });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="Login">
      <CssBaseline />
      <LoginTemplate title="Login">
        <LoginContainer
          isLoading={isLoading}
          onLoginClick={(username, password) => loginClicked(username, password)}
        />
      </LoginTemplate>
    </div>
  );
}
